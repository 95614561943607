import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

import Article from '../components/Article'
import Layout from '../components/Layout'
import { baseGrid } from '../theme/mixins'

export const data = graphql`
  query ($slug: String!, $lang: String!) {
    meta: metaJson(lang: { eq: $lang }) {
      ...meta
    }
    mdx(frontmatter: { lang: { eq: $lang }, slug: { eq: $slug } }) {
      body
      frontmatter {
        ...frontmatter
      }
    }
  }
`

const ArticlePage = ({ data }) => {
  const { meta, mdx } = data

  const articleStyle = css`
    ${baseGrid}
    padding-top: 4rem;
  `
  return (
    <Layout meta={meta}>
      <Helmet title={mdx.frontmatter.title} />
      <div css={articleStyle}>
        <Article article={mdx} />
      </div>
    </Layout>
  )
}

export default ArticlePage
